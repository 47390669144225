import { TRole } from '../pages/auth/interfaces';
import { menuConfigItems } from '../constants/menuConfigItems';

const {
  profile,
  vendorProducts,
  products,
  orders,
  users,
  legal,
  legalManager,
  settings,
  // catalog,
  // blog,
  guestBlog,
  // tariffs,
   companies,
  // vendorUsers,
  vendorCompany,
  catalogForUser,
  // chats,
  // tenders,
  // buyersTenders,
  // vendorsTenders,
  // addTender,
  // allTenders,
  // buyersDocuments,
  // buyersOrders,
  usersManager,
  inHouseDesignerTenders,
  inHouseDesignerAllTenders,
  vendorOrders,
  repairerProducts,
  legalOther,
} = menuConfigItems;

const getDefaultMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      // vendorsTenders,
      // catalogForUser,
      profile,
      // blog,
    ],
  },
});

const getManagerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      // catalogForUser,
      // profile,
      products,
      orders,
      usersManager,
      companies,
      // blog,
      settings,
      legal,  // с правами редактирования как у админа
      legalOther,
     // legalManager, // без прав редактирования
      // inHouseDesignerTenders,
    ],
  },
});
const getInhouseDesignerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [inHouseDesignerTenders,inHouseDesignerAllTenders],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      products,
      orders,
      // allTenders,
      users,
      companies,
      // tariffs,
      settings,
      legal,
      legalOther,
      // blog,
      // chats,
    ],
  },
});

const getVendorMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      vendorProducts,
      // vendorsTenders,
      // buyersDocuments,
      vendorCompany,
      // blog,
      // chats,
      vendorOrders
    ],
  },
});
const getRepairerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      repairerProducts,
      // vendorsTenders,
      // buyersDocuments,
      vendorCompany,
      // blog,
      // chats,
      vendorOrders
    ],
  },
});

const getBuyerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      // buyersOrders,
      // buyersTenders,
      // catalogForUser,
      // guestBlog,
    ],
  },
});const getDesignerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      // buyersOrders,
      // buyersTenders,
      // catalogForUser,
      // guestBlog,
    ],
  },
});

const getGuestMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      // catalog,
      // guestBlog
    ],
  },
});

const getAgentMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [inHouseDesignerTenders],
  },
});

const getMenuConfig = (userRoles: TRole[] = ['ROLE_BUYER'], isAuthorized?: boolean) => {
  if (isAuthorized) {
    if (userRoles === undefined) {
      return getDefaultMenu();
    }
    if (userRoles[0] === 'ROLE_ADMIN') {
      return getAdminMenu();
    }
    if (userRoles[0] === 'ROLE_MANAGER') {
      return getManagerMenu();
    }
    if (userRoles[0] === 'ROLE_DESIGNER') {
      return getDesignerMenu();
    }
    if (userRoles[0] === 'ROLE_INHOUSE_DESIGNER') {
      return getInhouseDesignerMenu();
    }
    if (userRoles[0] === 'ROLE_VENDOR') {
      return getVendorMenu();
    }
    if (userRoles[0] === 'ROLE_REPAIRER') {
      return getRepairerMenu();
    }
    if (userRoles[0] === 'ROLE_BUYER') {
      return getBuyerMenu();
    }
    if (userRoles[0] === 'ROLE_AGENT') {
      return getAgentMenu();
    }
  } else {
    return getGuestMenu();
  }
};

export default getMenuConfig;
