import axios from 'axios';
import {
  addEstimateToCartUrl,
  addProuctToCartUrl,
  checkoutCartUrl,
  clearCartUrl,
  createCartUrl,
  deleteCartUrl,
  getCartUrl,
  setProductCountUrl,
} from '../constants';
import { ICartCheckout } from '../pages/home/cart/interfaces';

export function setProductCount({
  hash,
  product_id,
  count,
}: {
  hash: string;
  product_id: number;
  count: number;
}) {
  return axios.put(setProductCountUrl(hash, product_id, count), {});
}

export function getCart(hash?: string | null) {
  return axios.get('/api/shop/cart', {
    params: {
      hash: hash || undefined,
    },
  });
}

export function deleteCart(hash: string) {
  return axios.delete(deleteCartUrl(hash));
}

export function clearCart(hash: string) {
  return axios.put(clearCartUrl(hash), {});
}

export function createCart() {
  return axios.post(createCartUrl(), {});
}

export function addProduct({
  product_id,
  estimate_id,
  count,
  hash,
}: {
  product_id: number;
  estimate_id?: number;
  count: number;
  hash: string;
}) {
  return !estimate_id ? axios.post(addProuctToCartUrl(hash, product_id, count), {}) :
      axios.post(addEstimateToCartUrl(hash, estimate_id, count), {}) ;
}

export function cartCheckout(data: ICartCheckout, hash: string) {
  return axios.post(checkoutCartUrl(hash), {
    address: data.location.address,
    status: 'new',
    fio: data.fio,
    phone: data.phone,
    email: data.email,
    useBonuse: data.useBonuse,
    deliveryPointId: data.deliveryPointId,
    city: data.city,
    flat: data.flat,
    region: data.region,
    home: data.home,
    street: data.street,
    received_at: data.received_at,
    deliveryType: data.deliveryType,
  });
}

export function cartEdit(
  fio: string,
  email: string,
  phone: string,
  hash: string,
  receivedDateTime: string
) {
  return axios.put(getCartUrl(hash), {
    fio: fio || undefined,
    email: email || undefined,
    phone: phone ? phone.replace(`+`, '').replace(/ /g, '').trim() : undefined,
    receivedDateTime,
  });
}
