import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';

import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { TAuthTabs } from '.';
import { useShowErrors } from '../../../hooks/useShowErrors';
import { useAxiosGetCallback } from '../../../hooks/useAxiosGet';
import { useFormikForgotPassword } from '../hooks/useFormikForgotPassword';

interface IProps {
  changeAuthTab: (newTab: TAuthTabs, props?: any) => void;
}

export const ForgotPassword: React.FC<IProps> = ({ changeAuthTab }) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [res, loading, err, makeRequest] = useAxiosGetCallback();
  const formik = useFormikForgotPassword(makeRequest);

  useEffect(() => {
    if (res) {
      enqueueSnackbar(intl.formatMessage({ id: 'AUTH.FORGOT.EMAIL_SENT' }), {
        variant: 'success',
      });
      changeAuthTab('CheckInSystem');
    }
  }, [res]);

  useShowErrors([err]);

  return (
    <div className='kt-login__body'>
      <div className='kt-login__form'>
        <div className='kt-login__title'>
          <h3>{intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_REQUIRED' })}</h3>
        </div>

        <form noValidate autoComplete='off' className='kt-form' onSubmit={formik.handleSubmit}>
          <div className='form-group'>
            <TextField
              type='text'
              margin='normal'
              label={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
              className='kt-width-full'
              name='email'
              autoFocus
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              helperText={formik.touched.email && formik.errors.email}
              error={Boolean(formik.touched.email && formik.errors.email)}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <div className='kt-login__actions' style={{ marginRight: 21 }}>
              {/* <button */}
              {/*  onClick={() => changeAuthTab('CheckInSystem')} */}
              {/*  type='button' */}
              {/*  className='btn btn-secondary btn-elevate kt-login__btn-secondary' */}
              {/* > */}
              {/*  {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })} */}
              {/* </button> */}
              <ButtonWithLoader
                  onPress={() => changeAuthTab('CheckInSystem')}
                  type="button"
                  variant="outlined"
              >
                {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
              </ButtonWithLoader>
            </div>

            <div className='kt-login__actions'>
              <ButtonWithLoader
                onPress={formik.handleSubmit}
                disabled={loading}
                loading={loading}
              >
                {intl.formatMessage({ id: 'AUTH.LOGIN.FORGOT_PASSWORD' })}
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
