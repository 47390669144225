export function googleDataToEntities(data) {
  try {
    return data.results.map(i => ({
      address: i.formatted_address,
      lng: i.geometry.location.lng,
      lat: i.geometry.location.lat,
    }));
  } catch (e) {
    return [];
  }
}
