import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ['Montserrat'].join(','),
    },

    breakpoints: {
      values: {
        sm: 544, // Small screen / phone
        md: 768, // Medium screen / tablet
        lg: 1024, // Large screen / desktop
        xl: 1200,
      },
    },

    palette: {
      contrastThreshold: 3,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#40271e',
        contrastText: '#fff',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        //main: '#0abb87',
        main: '#bf7839',
        // dark: will be calculated from palette.primary.main,
        contrastText: '#ffffff',
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#bf7839',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      success: {
        // light: will be calculated from palette.primary.main,
        main: '#0abb87',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },
    overrides: {
      // PrivateNotchedOutline: {
      //   legendNotched: {
      //     maxWidth: '0px',
      //   }
      // },
      MuiButton: {
        // raisedPrimary: {
        //   color: 'white',
        // },
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */

    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        // raisedPrimary: { color: '#fff' },
        disableRipple: true, // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1,
      },
    },
  }
);

export default function ThemeProvider(props) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
