import React, { useCallback, useState } from 'react';
import { Card, CardContent, Button, CardActions } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { ICategoryTreeTable } from '../interfaces';
import { ICategoryItem } from '../../categories/interfaces';
import {useURLSearchParams} from "../../../../hooks/useURLSearchParams";

type StyledTreeItemProps = {
  select: (id: number) => void;
  selectedCategoryId: number | null;
  item: ICategoryItem;
  classes: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      margin: 0,
      paddingLeft: 20,
      listStyle: 'none',
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color)`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&:hover > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    cardCategory: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 16,
    },
    buttons: {
      marginRight: theme.spacing(2),
    },
    button: {
      '&:active': {
        opacity: 0.2,
      },
    },
    content: {
      color: theme.palette.text.primary,
      fontWeight: 500,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      position: 'relative',
      transition: '.25s ease-out',
    },
    labelContainerChildren: {
      maxHeight: '100%',
      overflow: 'hidden',
    },
    labelChildren: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      paddingLeft: 30,
      transition: '.25s ease-out',
    },
    labelIcon: {},
    labelTitle: {
      fontWeight: 700,
      flexGrow: 1,
      fontSize: 14,
      color: '#282a3c',
    },
    labelText: {
      fontWeight: 600,
      flexGrow: 1,
      fontSize: 14,
      color: '#282a3c',
      opacity: 0.8,
    },
    activeItem: {
      backgroundColor: 'rgba(108,149,169,0.1)',
      color: theme.palette.primary.main,
    },
    hide: {
      height: 0,
    },
  })
);

type TestProps = {
  select: (id: number) => void;
  categories: ICategoryItem[];
  selectChildrenId: number | null;
  setSelectChildrenId: (id: number) => void;
  locale: string;
  checkProductsNum?: boolean;
};

export const StyledTreeItem = ({
  categories,
  select,
  setSelectChildrenId,
  selectChildrenId,
  locale,
  checkProductsNum,
}: TestProps) => {
  return (
    <li>
      {categories.map(item => (
        // <>
        //   {item?.products_num !== 0 ? (
        //     <StyledTreeSubItem
        //       category={item}
        //       select={select}
        //       selectChildrenId={selectChildrenId}
        //       setSelectChildrenId={setSelectChildrenId}
        //       locale={locale}
        //     />
        //   ) : null}
        // </>
        <>
          {checkProductsNum ? (
            <>
              {item.products_num && item.products_num > 0 ? (
                <StyledTreeSubItem
                  category={item}
                  select={select}
                  selectChildrenId={selectChildrenId}
                  setSelectChildrenId={setSelectChildrenId}
                  locale={locale}
                  checkProductsNum
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <StyledTreeSubItem
              category={item}
              select={select}
              selectChildrenId={selectChildrenId}
              setSelectChildrenId={setSelectChildrenId}
              locale={locale}
            />
          )}
        </>
      ))}
    </li>
  );
};

type TestPropsItem = {
  category: ICategoryItem;
  select: (id: number) => void;
  selectChildrenId: number | null;
  setSelectChildrenId: (id: number) => void;
  checkProductsNum?: boolean;
  locale: string;
};
export const StyledTreeSubItem = ({
  category,
  select,
  setSelectChildrenId,
  selectChildrenId,
  locale,
  checkProductsNum,
}: TestPropsItem) => {
  const classes = useTreeItemStyles();
  const [selectChild, setSelectChild] = useState<boolean>( category.children?.some(i => i.id === selectChildrenId ) || false );
  // @ts-ignore
  // const nameLang = locale === 'ru' ? category.name : category.names[`name_${locale}`];

  const handleItem = (id: number) => {
    select(id);
    category && category.id && setSelectChildrenId(category.id);
  };
  const onClickHandler = () => {
    setSelectChild(!selectChild);
  };

  return (
    <>
      {/* показывать категории в которых есть товары  {category.products_num !== undefined && category.products_num > 0 ? ( */}
      <li style={{ marginLeft: '20px' }}>
        <div
          onClick={() => {
            onClickHandler();
            category && category.id && handleItem(category.id);
            category && category.id && setSelectChildrenId(category.id);
          }}
          className={clsx(classes.labelRoot, {
            [classes.activeItem]: category.id === selectChildrenId,
          })}
        >
          <div style={{ position: 'absolute', left: -20 }}>
            {Boolean(category.children?.length) &&
              (selectChild ? <ArrowDropDownIcon /> : <ArrowRightIcon />)}
          </div>
          <Typography
            variant='body2'
            // className={!nameLang ? classes.fontNoLang : classes.labelText}
            className={classes.labelText}
            // style={{ color: category.id === selectChildrenId ? '#d01c1f' : '#000000' }}
          >
            {category.name}
          </Typography>
        </div>
        {/* поменять на !selectChild чтоб категории показывать  в развернутом виде */}
        {selectChild && category.children?.length ? (
          <StyledTreeItem
            categories={category.children}
            select={select}
            selectChildrenId={selectChildrenId}
            setSelectChildrenId={setSelectChildrenId}
            locale={locale}
            checkProductsNum={checkProductsNum}
          />
        ) : null}
      </li>
      {/* ) : null */}
      {/* } */}
    </>
  );
};

const FilterCategoriesTree: React.FC<ICategoryTreeTable> = ({
  categories,
  searchProducts,
  resetProducts,
  filter,
  setSearch,
  setSelectedCategoryId,
  selectedCategoryId,
  locale,
}) => {
  const intl = useIntl();
  const { setParams, deleteParam } = useURLSearchParams();
  const classes = useTreeItemStyles();
 // const [selectChildrenId, setSelectChildrenId] = useState<number>(0);
  // const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const fetch = useCallback(() => {
    setSearch(true);
    selectedCategoryId && setParams('category', selectedCategoryId.toString());
    selectedCategoryId && localStorage.setItem('category', selectedCategoryId.toString());
    selectedCategoryId &&
      // @ts-ignore
      searchProducts({ page: 1, perPage: 12, filter, categoryId: selectedCategoryId, locale });
  }, [selectedCategoryId, filter]);

  const select = (id: number) => {
    setSelectedCategoryId(id);
  };

  const clear = () => {
    resetProducts();
    if (filter.parameters.length === 0 && !filter.price_from && !filter.price_to) {
      setSearch(false);
    }
    deleteParam('category');
    localStorage.removeItem('category');
    setSelectedCategoryId(null);
  };
  return (
    <Card className={classes.cardCategory}>
      <CardContent>
        <Typography variant='h5' className={classes.labelTitle} style={{ marginBottom: 16 }}>
          {intl.formatMessage({ id: 'CATEGORIES.TITLE' })}
        </Typography>
        <ul className={classes.root}>
          <StyledTreeItem
            categories={categories}
            select={select}
            setSelectChildrenId={setSelectedCategoryId}
            selectChildrenId={selectedCategoryId}
            locale={intl.locale}
            checkProductsNum
          />
        </ul>
      </CardContent>
      <CardActions className={classes.cardButtons}>
        <Button onClick={clear} className={classes.buttons} variant='outlined' color='primary'>
          {intl.formatMessage({ id: 'PRODUCT.FILTER.RESET' })}
        </Button>
        <Button
          disabled={Boolean(!selectedCategoryId)}
          className={clsx(classes.button)}
          color='primary'
          variant='contained'
          onClick={fetch}
        >
          {intl.formatMessage({ id: 'PRODUCT.FILTER.SEARCH' })}
        </Button>
      </CardActions>
    </Card>
  );
};

export default FilterCategoriesTree;
