import React, {CSSProperties, useState} from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface IProps {
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  onPress?: any;
  variant?: 'text' | 'outlined' | 'contained';
  style?: CSSProperties;
}

const useStyles = makeStyles({
  isLoading: {
    paddingRight: '3.5rem!important',
  },
  buttonOpacity: {
    opacity: 1,
    transition: 'opacity 0.5s ease-in-out',
    '&:active': {
      opacity: 0.6,
    },
  },
});

const ButtonWithLoader: React.FC<IProps> = ({
  children,
  type = 'submit',
  disabled = false,
  loading = false,
  variant='contained',
  onPress = () => {},
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      type={type}
      variant={variant}
      color='primary'
      disabled={disabled}
      onClick={onPress}
      className={clsx(
          'btn btn-primary btn-elevate kt-login__btn-primary',
          variant === 'outlined' ? 'kt-login__btn-outlined' : '',
          loading && 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light',
          loading && classes.isLoading,
          classes.buttonOpacity,
      )}
    >
      {children}
    </Button>
  );
};

export default ButtonWithLoader;
