import axios from 'axios';
import { ICreateEstimate } from '../interfaces/estimate';

export const getEstimate = (id: number | string) => axios.get(`/api/shop/estimate/${id}`);

export const createEstimate = (project_id: number | string, data: ICreateEstimate) =>
  axios.post(`/api/shop/estimate`, data, { params: { project_id } });

export const editEstimate = (id: number | string, data: ICreateEstimate) =>
  axios.put(`/api/shop/estimate/${id}`, data);

export const getEstimates = (
  project_id: number | string,
  page = 1,
  per_page = 20,
  active?: boolean,
  lat?: number,
  lng?: number
) =>
  axios.get(`/api/shop/estimates`, {
    params: {
      project_id,
      page,
      per_page,
      active: active!! || null,
      lat,
      lng
    },
  });

export const createSection = (name: string, estimate_id: number | string) =>
  axios.post('/api/shop/estimate_section', { name }, { params: { estimate_id } });

export const editSection = (name: string, id: number | string) =>
  axios.put(`/api/shop/estimate_section/${id}`, { name });

export const getSections = (estimate_id: number | string, page = 1, per_page = 20) =>
  axios.get(`/api/shop/estimate_sections`, {
    params: {
      estimate_id,
      page,
      per_page,
    },
  });

export const deleteSection = (with_items: boolean, id: number | string) =>
  axios.delete(`/api/shop/estimate_sections/${id}`, {
    params: {
      with_items,
    },
  });

export const updatePriceEstimate = (id: number | string) =>
axios.put(`/api/shop/estimate/${id}/update`);

export const deliteEstimate = (id: number) =>
axios.delete(`/api/shop/estimate/${id}`,);
