/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import objectPath from 'object-path';
import {withRouter} from 'react-router-dom';

import BreadCrumbs from './components/BreadCrumbs';
import {LayoutContextConsumer} from '../LayoutContext';
import * as builder from '../../ducks/builder';
// import SearchField from '../../../app/components/ui/SearchField';
import ButtonAddProduct from '../../../app/components/ui/Buttons/ButtonAddProduct';
import {makeStyles, Popover} from "@material-ui/core";
import AutocompleteLocations from "../../../app/components/AutocompleteLocations";
import {actions as locationActions} from "../../../app/store/ducks/yaLocations.duck";
import {actions as productCatalogActions} from "../../../app/store/ducks/product-catalog.duck";
import {useIntl} from "react-intl";
// import { UserRoles } from '../../../app/interfaces/user';

// import { QuickActions } from './components/QuickActions';
// import { ReactComponent as SortNum1Icon } from '../../../_metronic/layout/assets/layout-svg-icons/SortNum1.svg';

const useStyles = makeStyles(theme => ({
  cartText: {
    color: theme.palette.secondary.main,
    maxWidth: 500,
    [theme.breakpoints.down(768)]: {
      maxWidth: "100%",
    },
  },
  autoLoc: {
    width: '100%',
  },
  cartBtn: {
    marginLeft: 40,
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(768)]: {
      marginLeft: 0,
    },
  },
}));

const SubHeader = (props) => {
  const {
    subheaderCssClasses,
    subheaderContainerCssClasses,
    subheaderMobileToggle,
    userRoles,
    me,
    locations,
    loadingLocations,
    byCoordsLoading,
    fetchLocations,
    clearLocations,
    fetchProducts,
    page,
    perPage
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const localLocation = sessionStorage.getItem('location');
  const parseLocalLocation = JSON.parse(localLocation);
  const [selected, setSelected] = useState({
    id: undefined,
    address: '',
    lng: 0,
    lat: 0,
    city: '',
    country: '',
    house: '',
    province: '',
    street: '',
    working_times: undefined,
  });

  // useEffect(() => {
  //   const location = localStorage.getItem('location');
  //   if (location) {
  //     try {
  //       const parseLocalLocation = JSON.parse(location);
  //       setSelected(parseLocalLocation);
  //     } catch (error) {
  //     }
  //   }
  // }, []);
  useEffect(() => {
    me?.location &&  localStorage.setItem('location', JSON.stringify({
      address:me.location.address,
      lat: me.location.lat,
      lng:me.location.lng,
    }))

    parseLocalLocation ?  setSelected(parseLocalLocation) : me?.location && setSelected({
      address:me.location.address,
      lat: me.location.lat,
      lng:me.location.lng,
    })
  }, [me])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <LayoutContextConsumer>
      {/*{({ subheader: { title, breadcrumb } }) => (*/}

      {({subheader: {title, breadcrumb, description, show}}) =>
        show && (
          <div
            id='kt_subheader'
            className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
          >
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
              <div className='kt-subheader__main'>
                <>
                  {subheaderMobileToggle && (
                    <button
                      className='kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left'
                      id='kt_subheader_mobile_toggle'
                    >
                      <span/>
                    </button>
                  )}

                  <div className='kt-subheader__wrap-left'>
                    {breadcrumb && <BreadCrumbs items={breadcrumb}/>}
                    {title && description && (
                      <span className='kt-subheader__separator kt-subheader__separator--v'/>
                    )}
                    {title && (
                      <h3 className='kt-subheader__title' style={{fontWeight: 600}}>
                        {title}
                      </h3>
                    )}
                    {props.location.pathname === '/products/catalog' && (
                      <>
                        <ButtonAddProduct/>
                      </>
                    )}
                  </div>
                  {props.location.pathname === '/products/catalog' && (
                      <div onClick={handleClick} className={`kt-user-card__avatar ${classes.cartBtn}`}>
                        <div
                          className={`kt-menu__link-text  ${classes.cartText}`}
                          style={{fontWeight: 700,  flex: 1}}
                        >
                          <AutocompleteLocations
                            options={locations || []}
                            loading={loadingLocations || byCoordsLoading}
                            inputValue={selected}
                            editable
                            label={intl.formatMessage({
                              id: 'STORE.INPUT.LOCATION',
                            })}
                            inputClassName={classes.autoLoc}
                            fetchLocations={fetchLocations}
                            clearLocations={clearLocations}
                            setSelectedLocation={location => {
                              setSelected(location || null);
                              localStorage.setItem('location', JSON.stringify(location));
                              sessionStorage.setItem('location', JSON.stringify(location));
                              fetchProducts({
                                page,
                                perPage,
                                filter: {parameters: [], price_from: '', price_to: ''},
                                categoryId: 0,
                                locale: location
                              })
                            }}
                            disable={false}
                            inputError={false}
                            inputHelperText={false}
                          />
                      </div>
                      {/* <Popover */}
                      {/*   id={id} */}
                      {/*   open={open} */}
                      {/*   anchorEl={anchorEl} */}
                      {/*   onClose={handleClose} */}
                      {/*   disableScrollLock={true} */}
                      {/*   anchorOrigin={{ */}
                      {/*     vertical: 'bottom', */}
                      {/*     horizontal: 'left', */}
                      {/*   }} */}
                      {/*   transformOrigin={{ */}
                      {/*     vertical: 'top', */}
                      {/*     horizontal: 'center', */}
                      {/*   }} */}
                      {/*   style={{ */}
                      {/*     top: '20px', */}
                      {/*   }} */}
                      {/* > */}
                      {/*   <div style={{ */}
                      {/*     maxWidth: 250, */}
                      {/*     minWidth: 250, */}
                      {/*   }}> */}
                      {/*      */}
                      {/*   </div> */}
                      {/* </Popover> */}
                    </div>
                  )}
                  {/* {!me ||
                      (!me?.roles.includes(UserRoles.ROLE_BUYER) &&
                        this.props.location.pathname === '/products/catalog' && (
                          <SearchField isSubHeader={true} />
                        ))} */}
                  {/* {userRoles?.[0] !== 'ROLE_VENDOR' && (
                    <Link to={`/cart`} className="kt-menu__link ">
                      <i className={`kt-menu__link-icon flaticon-cart`} />
                      <span
                        className="kt-menu__link-text"
                        style={{ paddingLeft: 10, fontWeight: 400 }}
                      >
                        <FormattedMessage
                          id="CART.SUBHEADER.TITLE"
                          defaultMessage={'CART.SUBHEADER.TITLE'}
                        />
                      </span>
                    </Link>
                  )} */}
                  {description && <span className='kt-subheader__desc'>{description}</span>}
                </>
              </div>
            </div>
          </div>
        )
      }
    </LayoutContextConsumer>
  );
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  userRoles: store.auth.user?.roles,
  subheaderMobileToggle: objectPath.get(store.builder.layoutConfig, 'subheader.mobile-toggle'),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader',
    toString: true,
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader_container',
    toString: true,
  }),
  me: store.profile.me,
  locations: store.yaLocations.yaLocations,
  loadingLocations: store.yaLocations.loading,
  page: store.productsCatalog.page,
  perPage: store.productsCatalog.per_page,

  byCoordsAddress: store.yaLocations.addressByCoords,
  byCoordsLoading: store.yaLocations.getLoacationByCoordsLoading,
  byCoordsSuccess: store.yaLocations.getLoacationByCoordsSuccess,
});

const mapDispatchToProps = {
  fetchLocations: locationActions.fetchRequest,
  clearLocations: locationActions.clear,
  fetchProducts: productCatalogActions.fetchRequest,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubHeader));
